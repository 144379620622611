import React from "react";
import miwiImage from "../assets/img/miwi_page.webp";
import sectionDivider from "../assets/img/section_divider_text_mobile.webp";

function About() {
  return (
    <section id="about">
      <div className="padding-block-700 padding-inline">
        <div className="text-center">
          <div className="even-columns">
            <img className="ab-img" src={miwiImage} alt="miwi" />
            <div className="ab-content">
              <h1 className="fs-primary-heading fw-bold">
                Miteinander - Wissen
              </h1>
              <div className="paragraph-wrapper">
                <p>
                  Eine der größten Berufsgruppe in der ambulanten Versorgung
                  unserer Patient:innen sind medizinische Fachangestellte,
                  Pflegefachkräfte, Praxismanager:innen und Auszubildende. Sie
                  sind sprichwörtlich Expert:innen, die täglich ein Höchstmaß an
                  wertvoller und unerlässlicher Arbeit in den Praxen mit und für
                  die Patient:innen gestalten und leisten.
                </p>
                <br />
                <p>
                  Zudem steht die Region Lausitz vor der Herausforderung, eine
                  zukunftsweisende und moderne medizinische Versorgung zu
                  entwickeln und zu erhalten. Um diese Herausforderung
                  mitzugestalten, entstand die Idee zur Etablierung einer
                  zukunftsorientierten und qualitätssichernden Dialogreihe und
                  Vernetzung ambulanter Expert:innen der nichtärztlichen
                  Berufsgruppe.
                </p>
                <br />
                <p>
                  Wir, das Team vom &nbsp;
                  <a href="http://www.an-sb.de/">
                    Ärzte und Gesundheitsnetz Südbrandenburg e.V.
                  </a>
                  &nbsp; verstehen es partizipativ und innovativ, diese
                  Berufsgruppen zusammenzuführen, zu fördern und im gemeinsamen
                  Dialog zu unterstützen. Daraufhin gründeten wir Ende April
                  2023 MiWi Lausitz.
                </p>
              </div>
              <div className="padding-block-500">
                <h2 className="ab-citaction fw-bold">
                  „Sein Sie dabei! Neue Innovationen leben davon, gemeinsam
                  gestaltet zu werden.“
                </h2>
                <a className="btn" href="#events">
                  Anmeldung zur Veranstaltung
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ab-end">
        <img src={sectionDivider} alt="sectionDivider" />
      </div>
    </section>
  );
}

export default About;
