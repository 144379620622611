import React from "react";
import clockIcon from "../../assets/img/Clock_Icon.webp";
import dropDownIcon from "../../assets/img/Dropdown_Icon_schwarz.webp";

function BlogCard({ date, header, id, ClickAction }) {
  return (
    <div className="bl-card" onClick={(e) => ClickAction(e, id)}>
      <div className="bl-card-content">
        <div className="bl-card-details">
          <img className="bl-card-details-img" src={clockIcon} alt="uhr" />
          <p className="bl-card-details-date">{date}</p>
        </div>

        <h1 className="bl-card-header fw-bold">{header}</h1>
      </div>
      <div className="bl-card-control">
        <span className="bl-card-btn">
          <img
            className="bl-card-btn-icon"
            src={dropDownIcon}
            alt="anmelde_icon"
          />
        </span>
      </div>
    </div>
  );
}

export default BlogCard;
