import React, { useState } from "react";
import axios from "axios";
import { HashLink } from "react-router-hash-link";

function ContactForm({ AfterSubmitAction }) {
  const [formData, setFormData] = useState({
    fullName: "",
    mail: "",
    subject: "",
    message: "",
  });

  const onSubmitForm = (e) => {
    e.preventDefault();
    sendSuggMail();
  };

  const sendSuggMail = async () => {
    var data = {
      fullName: formData.fullName,
      mail: formData.mail,
      subject: formData.subject,
      message: formData.message,
    };

    axios.post("server/sendContactMail.php", data).then(function (response) {
      AfterSubmitAction(true);
    });
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <form data-type="contact" onSubmit={onSubmitForm}>
      <div className="form-control">
        <label htmlFor="fullName" className="form-label">
          Name
        </label>
        <input
          className="form-input"
          onChange={handleChange}
          type="text"
          name="fullName"
          required
        />
      </div>

      <div className="form-control">
        <label htmlFor="mail" className="form-label">
          E-Mail
        </label>
        <input
          className="form-input"
          onChange={handleChange}
          type="mail"
          name="mail"
          required
        />
      </div>

      <div className="form-control">
        <label htmlFor="subject" className="form-label">
          Betreff
        </label>
        <input
          className="form-input"
          onChange={handleChange}
          type="text"
          name="subject"
          required
        />
      </div>

      <div className="form-control">
        <label htmlFor="message" className="form-label">
          Nachricht
        </label>
        <textarea
          className="form-input"
          onChange={handleChange}
          name="message"
          required
        />
      </div>
      <div className="form-control">
        <span className="form-control-info">
          <input
            type="checkbox"
            placeholder="Datenschutz"
            required
            name="datenschutz"
          />
          Ich willige in die in der{" "}
          <HashLink to="/datenschutz">Datenschutzerklärung</HashLink>{" "}
          beschriebene Verarbeitung meiner Daten ein: <span>*</span>
        </span>
      </div>
      <button type="submit" className="btn" data-type="inverted">
        Absenden
      </button>
    </form>
  );
}

export default ContactForm;
