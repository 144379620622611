import React, { useState } from "react";
import closeBtnIcon from "../../assets/img/x.webp";
import SuggesstionForm from "./Forms/SuggesstionForm";

function SuggesstionModal({ Props, Actions, closeModalFunction }) {
  const closeModal = Actions["closeModal"];
  const [showConfirmation, setShowConfirmation] = useState(false);

  const SuggFormComponent = () => {
    if (showConfirmation) {
      return (
        <>
          <p>Herzlichen Dank für ihren Themenvorschlag.</p>
          <div className="btn" onClick={closeModalFunction}>
            Schließen
          </div>
        </>
      );
    } else {
      return <SuggesstionForm AfterSubmitAction={setShowConfirmation} />;
    }
  };

  return (
    <div className="modal">
      <span className="modal-btn-close">
        <img src={closeBtnIcon} alt="anmelde_icon" onClick={closeModal} />
      </span>
      <div className="modal-event-body">
        <SuggFormComponent />
      </div>
    </div>
  );
}

export default SuggesstionModal;
