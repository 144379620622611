import React from "react";
import heroImage from "../assets/img/hero_page.webp";
import "../assets/styles/App.css";

function Home() {
  return (
    <section id="home">
      <div className="even-areas">
        <div className="hr-wrapper padding-inline">
          <h1 className="hr-header fw-bold">Miteinander Wissen</h1>
          <h2 className="hr-sub-header">
            Für medizinische Fachangestellte, Pflegefachkräfte und
            Praxismanager_innen ambulanter Praxen
          </h2>
          <p className="hr-info">
            Eine der größten Berufsgruppen in der ambulanten Versorgung unserer
            Patient:innen sind medizinische Fachangestellte, Pflegefachkräfte
            und Praxismanager:innen und Auszubildene. Sie sind sprichtwörtlich
            Expert:innen...
          </p>
          <a
            className="btn"
            data-location="hr"
            data-type="inverted"
            href="#about"
          >
            Weitere Informationen
          </a>
        </div>
        <div className="hr-img-wrapper">
          <img className="hr-img" src={heroImage} alt="MainPage" />
        </div>
      </div>
    </section>
  );
}

export default Home;
