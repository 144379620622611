import React from "react";
import { HashLink } from "react-router-hash-link";
import anmeldeIcon from "../assets/img/Anmelde_Icon.webp";
import burgerMenu from "../assets/img/burgerMenu.webp";
import logo from "../assets/img/logo_miwi.webp";
import { useState } from "react";

function Navbar() {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  const openMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  const onNavLinkClicked = () => {
    setShowMobileMenu(false);
  };

  const MobileMenu = () => {
    if (showMobileMenu) {
      return (
        <div className="nav-mobile-links">
          <HashLink className="nav-link" to="/#home" onClick={onNavLinkClicked}>
            Start
          </HashLink>
          <HashLink
            className="nav-link"
            to="/#about"
            onClick={onNavLinkClicked}
          >
            Über
          </HashLink>
          <HashLink className="nav-link" to="/#blog" onClick={onNavLinkClicked}>
            Blog
          </HashLink>
          <HashLink
            className="nav-link"
            to="/#events"
            onClick={onNavLinkClicked}
          >
            Veranstaltungen
          </HashLink>
          <HashLink
            className="btn-icon"
            to="/#events"
            onClick={onNavLinkClicked}
          >
            <span className="btn-icon-wrapper">
              <img src={anmeldeIcon} alt="anmelde_icon" />
            </span>
            <span className="btn-icon-text">ANMELDEN</span>
          </HashLink>
        </div>
      );
    }
    return;
  };

  return (
    <header className="primary-header">
      <div className="nav">
        <HashLink to="/#top">
          <img className="nav-logo" src={logo} alt="logo" />
        </HashLink>
        <div className="nav-links">
          <div className="nav-extended-links">
            <HashLink className="btn-icon" to="/#events">
              <span className="btn-icon-wrapper">
                <img src={anmeldeIcon} alt="anmelde_icon" />
              </span>
              <span className="btn-icon-text">ANMELDEN</span>
            </HashLink>
          </div>
          <div className="nav-mobile">
            <img src={burgerMenu} alt="navMenu" onClick={openMobileMenu} />
          </div>
        </div>
      </div>
      <MobileMenu />
    </header>
  );
}

export default Navbar;
