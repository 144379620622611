import React, { useEffect, useState } from "react";
import closeBtnIcon from "../../assets/img/x.webp";
import ImageSlider from "./ImageSlider";
import parse from "html-react-parser";

function BlogModal({ Props, Actions }) {
  const closeModal = Actions["closeModal"];
  const date = Props["date"];
  const imgList = Props["imgList"];
  const textList = Props["textList"];

  /*
  const [ImagesComponent, setImagesComponent] = useState(<></>);

  useEffect(() => {
    if (imgList.length > 1) {
      return setImagesComponent(<ImageSlider imgList={imgList} />);
    } else {
      return setImagesComponent(
        <img src={imgList[0]} className="blog-modal-img" alt="modal-img" />
      );
    }
  }, [imgList]); */

  const TextComponent = () => {
    if (textList.length > 1) {
      const textComp = [];

      return (
        <p className="blog-modal-text">
          {textList.map((entry) => {
            return <>{parse(entry)}</>;
          })}
        </p>
      );
    } else {
      return parse("<p className='blog-modal-text'>" + textList[0] + "</p>");
    }
  };

  const ImagesComponent = () => {
    if (imgList.length > 1) {
      return <ImageSlider imgList={imgList} />;
    } else if (imgList.length === 1) {
      return (
        <img src={imgList[0]} className="blog-modal-img" alt="modal-img" />
      );
    } else {
      return <> </>;
    }
  };

  return (
    <div id="blog-modal" className="modal">
      <span className="modal-btn-close">
        <img src={closeBtnIcon} alt="anmelde_icon" onClick={closeModal} />
      </span>
      <div className="blog-modal-body">
        <p className="blog-modal-date">{date}</p>
        <div className="blog-modal-content">{ImagesComponent()}</div>
        {TextComponent()}
      </div>
    </div>
  );
}

export default BlogModal;
