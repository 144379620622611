import React from "react";
import BlogCard from "../components/Blog/BlogCard";
import { useState } from "react";
import BlogModal from "../components/Blog/BlogModal";
import Modal from "../components/Modal";
import blogEntries from "../data/blog.json";

function Blog() {
  const [showBlogModal, setShowBlogModal] = useState(false);
  const [blogModal, setBlogModal] = useState({
    date: "",
    imgList: [],
    textList: [],
  });

  const onCardClick = (e, id) => {
    var blogEntry = blogEntries.find((entry) => entry.id === id);
    setBlogModal({
      date: blogEntry.date,
      imgList: blogEntry.imgList,
      textList: blogEntry.textList,
    });
    setShowBlogModal(!showBlogModal);
  };

  const closeModal = () => {
    setShowBlogModal(false);
  };

  const RenderBlogModal = () => {
    if (showBlogModal) {
      return (
        <Modal
          ContentObject={BlogModal}
          ContentProps={{
            date: blogModal.date,
            imgList: blogModal.imgList,
            textList: blogModal.textList,
          }}
          ContentActions={{ closeModal: closeModal }}
        />
      );
    }
    return <></>;
  };

  return (
    <>
      <section id="blog">
        <RenderBlogModal />
        <div className="padding-block-700 padding-inline">
          <h1 className="fs-primary-heading fw-bold text-center">Blog</h1>
          <div className="bl-card-list">
            {blogEntries.map((entry) => {
              return (
                <BlogCard
                  date={entry.date}
                  header={entry.header}
                  id={entry.id}
                  ClickAction={onCardClick}
                />
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}

export default Blog;
