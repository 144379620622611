import React from "react";
import dropDownIcon from "../../assets/img/Dropdown_Icon_schwarz.png";

function EventCard({ item, ClickAction }) {
  const renderController = () => {
    if (item.signUpActiv) {
      return (
        <div className="ap-card-control">
          <span className="bl-card-btn">
            <img
              className="bl-card-btn-icon"
              src={dropDownIcon}
              alt="anmelde_icon"
            />
          </span>
        </div>
      );
    }
  };

  return (
    <div
      className="ap-card"
      onClick={item.signUpActiv ? () => ClickAction() : console.log()}
    >
      <div className="ap-card-body">
        <div className="ap-card-details text-center">
          <p className="ap-card-details-date fw-bold"></p>
          <p className="ap-card-details-time">{item.date}</p>
        </div>
        <div className="ap-card-content">
          <p className="ap-card-header fw-bold">{item.title}</p>
        </div>
        <div className="ap-card-signUp-info">
          {item.signUpActiv
            ? ""
            : "Anmeldung möglich ab dem " + item.readyForSignUp}
        </div>
      </div>
      {renderController()}
    </div>
  );
}

export default EventCard;
