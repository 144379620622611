import React, { useEffect, useState } from "react";
import sliderPrevBtn from "../../assets/img/Chevron-left.png";
import sliderNextBtn from "../../assets/img/Chevron-right.png";

function ImageSlider({ imgList }) {
  const [sliderList, setSliderList] = useState([]);
  const [activeSliderIndex, setActiveSliderIndex] = useState(0);
  const maxSliderIndex = imgList.length - 1;

  useEffect(() => {
    RenderSliderList(activeSliderIndex);
  }, [activeSliderIndex]);

  const RenderSliderList = (activeIndex) => {
    return setSliderList(
      <img
        src={imgList[activeIndex]}
        className="blog-modal-img slider-img-active"
        alt="modal-img"
      />
    );
  };

  const onClickSliderPrev = () => {
    if (activeSliderIndex - 1 < 0) {
      setActiveSliderIndex(maxSliderIndex);
    } else {
      var prevSliderIndex = activeSliderIndex - 1;
      setActiveSliderIndex(prevSliderIndex);
    }
  };

  const onClickSliderNext = () => {
    if (activeSliderIndex + 1 > maxSliderIndex) {
      setActiveSliderIndex(0);
    } else {
      var nextSliderIndex = activeSliderIndex + 1;
      setActiveSliderIndex(nextSliderIndex);
    }
  };

  return (
    <div class="blog-modal-img-slider">
      <img
        class="blog-modal-img-slider-control"
        id="slider-control-prev"
        src={sliderPrevBtn}
        alt="btn-prev"
        onClick={onClickSliderPrev}
      />
      {sliderList}
      <img
        class="blog-modal-img-slider-control"
        id="slider-control-next"
        src={sliderNextBtn}
        alt="btn-next"
        onClick={onClickSliderNext}
      />
    </div>
  );
}

export default ImageSlider;
