import React from "react";
import footerLogo from "../assets/img/logo.webp";
import ansbLogo from "../assets/img/ansb_logo.webp";
import { NavHashLink, HashLink } from "react-router-hash-link";

function Footer() {
  return (
    <footer>
      <div className="padding-block-700 padding-inline">
        <div className="primary-footer-wrapper">
          <div className="primary-footer-links">
            <a className="footer-logo-links" href="http://www.an-sb.de/">
              <img src={ansbLogo} alt="verlinkungANSB" />
            </a>
            <NavHashLink to="/#top" className="footer-logo-links">
              <img src={footerLogo} alt="verlinkungHome" />
            </NavHashLink>
          </div>
          <div className="primary-footer-nav">
            <nav className="footer-nav">
              <ul aria-label="Footer">
                <li>
                  <HashLink to="/kontakt">Kontakt</HashLink>
                </li>
                <li>
                  <HashLink to="/datenschutz">Datenschutz</HashLink>
                </li>
                <li>
                  <HashLink to="/impressum">Impressum</HashLink>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
