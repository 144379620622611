import React from "react";

function Modal({
  ContentObject,
  ContentProps,
  ContentActions,
  closeModalFunction,
}) {
  const closeModal = ContentActions["closeModal"];

  return (
    <>
      <ContentObject
        Props={ContentProps}
        Actions={ContentActions}
        closeModalFunction={closeModalFunction}
      />
      <div className="overlay" onClick={closeModal}></div>
    </>
  );
}

export default Modal;
