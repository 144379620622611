import React, { useState } from "react";
import { HashLink } from "react-router-hash-link";
import axios from "axios";

function EventForm({ AfterSubmitAction }) {
  const [formData, setFormData] = useState({
    praxis: "",
    address: "",
    mail: "",
    p1: "",
    p2: "",
    p3: "",
  });

  const onSubmitForm = (e) => {
    e.preventDefault();
    sendSubmitMail();
  };

  const sendSubmitMail = async () => {
    var data = {
      praxis: formData.praxis,
      adress: formData.address,
      mail: formData.mail,
      p1: formData.p1,
      p2: formData.p2,
      p3: formData.p3,
    };

    axios.post("server/sendSignUpMail.php", data).then(function (response) {
      AfterSubmitAction();
    });
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <form data-type="signIn" id="signInForm" onSubmit={onSubmitForm}>
      <h1 className="form-header">Anmeldung</h1>
      <div className="form-control">
        <label htmlFor="praxis" className="form-label">
          Praxisname <span>*</span>
        </label>
        <input
          className="form-input"
          onChange={handleChange}
          type="text"
          name="praxis"
          required
        />
      </div>

      <div className="form-control">
        <label htmlFor="address" className="form-label">
          Anschrift <span>*</span>
        </label>
        <input
          className="form-input"
          onChange={handleChange}
          name="address"
          type="text"
          required
        />
      </div>

      <div className="form-control">
        <label htmlFor="mail" className="form-label">
          E-Mail <span>*</span>
        </label>
        <input
          className="form-input"
          onChange={handleChange}
          autoComplete
          name="mail"
          type="mail"
          required
        />
      </div>

      <div className="form-control">
        <label htmlFor="p1" className="form-label">
          Teilnehmer 1 <span>*</span>
        </label>
        <input
          className="form-input"
          onChange={handleChange}
          name="p1"
          type="text"
          required
        />
      </div>

      <div className="form-control">
        <label htmlFor="p2" className="form-label">
          Teilnehmer 2
        </label>
        <input
          className="form-input"
          onChange={handleChange}
          name="p2"
          type="text"
        />
      </div>

      <div className="form-control">
        <label htmlFor="p3" className="form-label">
          Teilnehmer 3
        </label>
        <input
          className="form-input"
          onChange={handleChange}
          name="p3"
          type="text"
        />
      </div>
      <div className="form-control">
        <span className="form-control-info">
          <input
            type="checkbox"
            placeholder="Datenschutz"
            required
            name="datenschutz"
          />
          Ich willige in die in der{" "}
          <HashLink to="/datenschutz">Datenschutzerklärung</HashLink>{" "}
          beschriebene Verarbeitung meiner Daten ein: <span>*</span>
        </span>
      </div>
      <button type="submit" className="btn">
        Absenden
      </button>
    </form>
  );
}

export default EventForm;
