import React, { Component } from "react";

export default class Datenschutz extends Component {
  componentDidMount() {
    window.scrollTo({ top: 0, behavior: "instant" });
  }

  render() {
    return (
      <div class="padding-block-500 padding-inline">
        <div class="container wrapper-datenschutz">
          <h1 class="fs-secondary-heading fw-bold">Datenschutz</h1>
          <br />
          <h3 class="fs-tertiary-heading fw-bold">
            1. Datenschutz auf einen Blick
          </h3>
          <br />
          <p class="fw-bold">Allgemeine Hinweise</p>
          <p>
            Die folgenden Hinweise geben einen einfachen Überblick darüber, was
            mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website
            besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
            persönlich identifiziert werden können. Ausführliche Informationen
            zum Thema Datenschutz entnehmen Sie unserer unter diesem Text
            aufgeführten Datenschutzerklärung.
          </p>
          <br />
          <p class="fw-bold">Datenerfassung auf dieser Website</p>
          <br />
          <p class="fw-bold">
            Wer ist verantwortlich für die Datenerfassung auf dieser Website?
          </p>
          <p>
            Die Datenverarbeitung auf dieser Website erfolgt durch den
            Websitebetreiber. Dessen Kontaktdaten können Sie dem Abschnitt
            „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung
            entnehmen.
          </p>
          <br />
          <p class="fw-bold">Wie erfassen wir Ihre Daten?</p>
          <p>
            Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
            mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in
            ein Kontaktformular eingeben. Andere Daten werden automatisch oder
            nach Ihrer Einwilligung beim Besuch der Website durch unsere
            IT-Systeme erfasst. Das sind vor allem technische Daten (z. B.
            Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die
            Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website
            betreten.
          </p>
          <br />
          <p class="fw-bold">Wofür nutzen wir Ihre Daten?</p>
          <p>
            Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
            der Website zu gewährleisten. Andere Daten können zur Analyse Ihres
            Nutzerverhaltens verwendet werden.
          </p>
          <br />
          <p class="fw-bold">Welche Rechte haben Sie bezüglich Ihrer Daten?</p>
          <p>
            Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft,
            Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
            erhalten. Sie haben außerdem ein Recht, die Berichtigung oder
            Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur
            Datenverarbeitung erteilt haben, können Sie diese Einwilligung
            jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht,
            unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen. Hierzu sowie zu weiteren
            Fragen zum Thema Datenschutz können Sie sich jederzeit an uns
            wenden.
          </p>
          <br />
          <p class="fw-bold">Analyse-Tools und Tools von Drittanbietern</p>
          <p>
            Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch
            ausgewertet werden. Das geschieht vor allem mit sogenannten
            Analyseprogrammen. Detaillierte Informationen zu diesen
            Analyseprogrammen finden Sie in der folgenden Datenschutzerklärung.
          </p>
          <br />
          <p class="fs-tertiary-heading fw-bold">
            2. Allgemeine Hinweise und Pflichtinformationen
          </p>
          <br />
          <p class="fw-bold">Datenschutz</p>
          <br />
          <p>
            Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen
            Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
            vertraulich und entsprechend der gesetzlichen
            Datenschutzvorschriften sowie dieser Datenschutzerklärung. Wenn Sie
            diese Website benutzen, werden verschiedene personenbezogene Daten
            erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich
            identifiziert werden können. Die vorliegende Datenschutzerklärung
            erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie
            erläutert auch, wie und zu welchem Zweck das geschieht. Wir weisen
            darauf hin, dass die Datenübertragung im Internet (z. B. bei der
            Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
            lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
            möglich.
          </p>
          <br />
          <p class="fw-bold">Hinweis zur verantwortlichen Stelle</p>
          <p>
            Die verantwortliche Stelle für die Datenverarbeitung auf dieser
            Website ist:
          </p>
          <p>Ärzte- und Gesundheitsnetz Südbrandenburg e.V.</p>
          <p>Westfalenstraße 2</p>
          <p>03238 Finsterwalde</p>
          <p>Telefon: +49 (0) 1523 1929494</p>
          <p>E-Mail: info@an-sb.de</p>
          <br />
          <p>
            Verantwortliche Stelle ist die natürliche oder juristische Person,
            die allein oder gemeinsam mit anderen über die Zwecke und Mittel der
            Verarbeitung von personenbezogenen Daten (z. B. Namen,
            E-Mail-Adressen o. Ä.) entscheidet.
          </p>
          <br />
          <p class="fw-bold">Speicherdauer</p>
          <p>
            Soweit innerhalb dieser Datenschutzerklärung keine speziellere
            Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten
            bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie
            ein berechtigtes Löschersuchen geltend machen oder eine Einwilligung
            zur Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern
            wir keine anderen rechtlich zulässigen Gründe für die Speicherung
            Ihrer personenbezogenen Daten haben (z.B. steuer- oder
            handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall
            erfolgt die Löschung nach Fortfall dieser Gründe.
          </p>
          <br />
          <p class="fw-bold">
            Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten
          </p>
          <p>
            Auf unserer Website sind unter anderem Tools von Unternehmen mit
            Sitz in den USA oder sonstigen datenschutzrechtlich nicht sicheren
            Drittstaaten eingebunden. Wenn diese Tools aktiv sind, können Ihre
            personenbezogene Daten in diese Drittstaaten übertragen und dort
            verarbeitet werden. Wir weisen darauf hin, dass in diesen Ländern
            kein mit der EU vergleichbares Datenschutzniveau garantiert werden
            kann. Beispielsweise sind US-Unternehmen dazu verpflichtet,
            personenbezogene Daten an Sicherheitsbehörden herauszugeben, ohne
            dass Sie als Betroffener hiergegen gerichtlich vorgehen könnten. Es
            kann daher nicht ausgeschlossen werden, dass US-Behörden (z.B.
            Geheimdienste) Ihre auf US-Servern befindlichen Daten zu
            Überwachungszwecken verarbeiten, auswerten und dauerhaft speichern.
            Wir haben auf diese Verarbeitungstätigkeiten keinen Einfluss.
          </p>
          <br />
          <p class="fw-bold">
            Widerruf Ihrer Einwilligung zur Datenverarbeitung
          </p>
          <p>
            Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
            Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
            jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf
            erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
          </p>
          <br />
          <p class="fw-bold">
            Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie
            gegen Direktwerbung (Art. 21 DSGVO)
          </p>
          <p>
            WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E
            ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN,
            DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE
            VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN;
            DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING.
            DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT,
            ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH
            EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT
            MEHR VERARBEITEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE
            GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE
            UND FREIHEITEN ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT DER
            GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN
            (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO). WERDEN IHRE
            PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN,
            SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG
            SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER
            WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT
            SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN,
            WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR ZUM
            ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2
            DSGVO).
          </p>
          <br />
          <p class="fw-bold">
            Beschwerderecht bei der zuständigen Aufsichtsbehörde
          </p>
          <p>
            Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
            Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
            Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
            oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht
            besteht unbeschadet anderweitiger verwaltungsrechtlicher oder
            gerichtlicher Rechtsbehelfe. Recht auf Datenübertragbarkeit Sie
            haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
            oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich
            oder an einen Dritten in einem gängigen, maschinenlesbaren Format
            aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten
            an einen anderen Verantwortlichen verlangen, erfolgt dies nur,
            soweit es technisch machbar ist.
          </p>
          <br />
          <p class="fw-bold">Auskunft, Löschung und Berichtigung</p>
          <p>
            Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
            jederzeit das Recht auf unentgeltliche Auskunft über Ihre
            gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger
            und den Zweck der Datenverarbeitung und ggf. ein Recht auf
            Berichtigung oder Löschung dieser Daten. Hierzu sowie zu weiteren
            Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an
            uns wenden.
          </p>
          <br />
          <p class="fw-bold">Recht auf Einschränkung der Verarbeitung</p>
          <p>
            Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen. Hierzu können Sie sich
            jederzeit an uns wenden. Das Recht auf Einschränkung der
            Verarbeitung besteht in folgenden Fällen:
          </p>
          <br />
          <ul>
            <li>
              <p>
                Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
                personenbezogenen Daten bestreiten, benötigen wir in der Regel
                Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie
                das Recht, die Einschränkung der Verarbeitung Ihrer
                personenbezogenen Daten zu verlangen.
              </p>
            </li>
            <li>
              <p>
                Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
                geschah/geschieht, können Sie statt der Löschung die
                Einschränkung der Datenverarbeitung verlangen.
              </p>
            </li>
            <li>
              <p>
                Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie
                sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von
                Rechtsansprüchen benötigen, haben Sie das Recht, statt der
                Löschung die Einschränkung der Verarbeitung Ihrer
                personenbezogenen Daten zu verlangen.
              </p>
            </li>
            <li>
              <p>
                Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt
                haben, muss eine Abwägung zwischen Ihren und unseren Interessen
                vorgenommen werden. Solange noch nicht feststeht, wessen
                Interessen überwiegen, haben Sie das Recht, die Einschränkung
                der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
              </p>
            </li>
          </ul>
          <br />
          <p>
            Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
            eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung
            abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung,
            Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der
            Rechte einer anderen natürlichen oder juristischen Person oder aus
            Gründen eines wichtigen öffentlichen Interesses der Europäischen
            Union oder eines Mitgliedstaats verarbeitet werden.
          </p>
          <br />
          <h3 class="fs-tertiary-heading fw-bold">
            3. Datenerfassung auf dieser Website
          </h3>
          <br />
          <p class="fw-bold">Cookies</p>
          <br />
          <p>
            Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind
            kleine Textdateien und richten auf Ihrem Endgerät keinen Schaden an.
            Sie werden entweder vorübergehend für die Dauer einer Sitzung
            (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem
            Endgerät gespeichert. Session-Cookies werden nach Ende Ihres Besuchs
            automatisch gelöscht. Permanente Cookies bleiben auf Ihrem Endgerät
            gespeichert, bis Sie diese selbst löschen oder eine automatische
            Löschung durch Ihren Webbrowser erfolgt. Teilweise können auch
            Cookies von Drittunternehmen auf Ihrem Endgerät gespeichert werden,
            wenn Sie unsere Seite betreten (Third-Party-Cookies). Diese
            ermöglichen uns oder Ihnen die Nutzung bestimmter Dienstleistungen
            des Drittunternehmens (z.B. Cookies zur Abwicklung von
            Zahlungsdienstleistungen). Cookies haben verschiedene Funktionen.
            Zahlreiche Cookies sind technisch notwendig, da bestimmte
            Websitefunktionen ohne diese nicht funktionieren würden (z.B. die
            Warenkorbfunktion oder die Anzeige von Videos). Andere Cookies
            dienen dazu, das Nutzerverhalten auszuwerten oder Werbung
            anzuzeigen. Cookies, die zur Durchführung des elektronischen
            Kommunikationsvorgangs (notwendige Cookies) oder zur Bereitstellung
            bestimmter, von Ihnen erwünschter Funktionen (funktionale Cookies,
            z. B. für die Warenkorbfunktion) oder zur Optimierung der Website
            (z.B. Cookies zur Messung des Webpublikums) erforderlich sind,
            werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert,
            sofern keine andere Rechtsgrundlage angegeben wird. Der
            Websitebetreiber hat ein berechtigtes Interesse an der Speicherung
            von Cookies zur technisch fehlerfreien und optimierten
            Bereitstellung seiner Dienste. Sofern eine Einwilligung zur
            Speicherung von Cookies abgefragt wurde, erfolgt die Speicherung der
            betreffenden Cookies ausschließlich auf Grundlage dieser
            Einwilligung (Art. 6 Abs. 1 lit. a DSGVO); die Einwilligung ist
            jederzeit widerrufbar.
          </p>
          <br />
          <p>
            Sie können Ihren Browser so einstellen, dass Sie über das Setzen von
            Cookies informiert werden und Cookies nur im Einzelfall erlauben,
            die Annahme von Cookies für bestimmte Fälle oder generell
            ausschließen sowie das automatische Löschen der Cookies beim
            Schließen des Browsers aktivieren. Bei der Deaktivierung von Cookies
            kann die Funktionalität dieser Website eingeschränkt sein.
          </p>
          <p>
            Soweit Cookies von Drittunternehmen oder zu Analysezwecken
            eingesetzt werden, werden wir Sie hierüber im Rahmen dieser
            Datenschutzerklärung gesondert informieren und ggf. eine
            Einwilligung abfragen.
          </p>
          <br />
          <p class="fw-bold" id="kontaktformular">
            Kontaktformular
          </p>
          <p>
            Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden
            Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort
            angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den
            Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir
            nicht ohne Ihre Einwilligung weiter.
          </p>
          <br />
          <p>
            Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs.
            1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
            zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
            erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung
            auf unserem berechtigten Interesse an der effektiven Bearbeitung der
            an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf
            Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese
            abgefragt wurde.
          </p>
          <br />
          <p>
            Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei
            uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur
            Speicherung widerrufen oder der Zweck für die Datenspeicherung
            entfällt (z. B. nach abgeschlossener Bearbeitung Ihrer Anfrage).
            Zwingende gesetzliche Bestimmungen – insbesondere
            Aufbewahrungsfristen – bleiben unberührt.
          </p>
          <br />
          <p class="fw-bold">Anfrage per E-Mail oder Telefon</p>
          <p>
            Wenn Sie uns per E-Mail oder Telefon kontaktieren, wird Ihre Anfrage
            inklusive aller daraus hervorgehenden personenbezogenen Daten (Name,
            Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns
            gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre
            Einwilligung weiter.
          </p>
          <br />
          <p>
            Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs.
            1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
            zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
            erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung
            auf unserem berechtigten Interesse an der effektiven Bearbeitung der
            an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf
            Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese
            abgefragt wurde. Die von Ihnen an uns per Kontaktanfragen
            übersandten Daten verbleiben bei uns, bis Sie uns zur Löschung
            auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der
            Zweck für die Datenspeicherung entfällt (z. B. nach abgeschlossener
            Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen –
            insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt.
          </p>
          <br />
          <p class="fs-tertiary-heading fw-bold">4. Newsletter</p>
          <br />
          <p class="fw-bold">Newsletterdaten</p>
          <br />
          <p>
            Wenn Sie den auf der Website angebotenen Newsletter beziehen
            möchten, benötigen wir von Ihnen eine E-Mail-Adresse sowie
            Informationen, welche uns die Überprüfung gestatten, dass Sie der
            Inhaber der angegebenen E-Mail-Adresse sind und mit dem Empfang des
            Newsletters einverstanden sind. Weitere Daten werden nicht bzw. nur
            auf freiwilliger Basis erhoben. Diese Daten verwenden wir
            ausschließlich für den Versand der angeforderten Informationen und
            geben diese nicht an Dritte weiter.
          </p>
          <br />
          <p>
            Die Verarbeitung der in das Newsletteranmeldeformular eingegebenen
            Daten erfolgt ausschließlich auf Grundlage Ihrer Einwilligung (Art.
            6 Abs. 1 lit. a DSGVO). Die erteilte Einwilligung zur Speicherung
            der Daten, der E-Mail-Adresse sowie deren Nutzung zum Versand des
            Newsletters können Sie jederzeit widerrufen, etwa über den
            „Austragen“-Link im Newsletter. Die Rechtmäßigkeit der bereits
            erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
          </p>
          <br />
          <p>
            Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten
            Daten werden von uns bis zu Ihrer Austragung aus dem Newsletter bei
            uns bzw. dem Newsletterdiensteanbieter gespeichert und nach der
            Abbestellung des Newsletters oder nach Zweckfortfall aus der
            Newsletterverteilerliste gelöscht. Wir behalten uns vor,
            E-Mail-Adressen aus unserem Newsletterverteiler nach eigenem
            Ermessen im Rahmen unseres berechtigten Interesses nach Art. 6 Abs.
            1 lit. f DSGVO zu löschen oder zu sperren.
          </p>
          <br />
          <p>
            Nach Ihrer Austragung aus der Newsletterverteilerliste wird Ihre
            E-Mail-Adresse bei uns bzw. dem Newsletterdiensteanbieter ggf. in
            einer Blacklist gespeichert, um künftige Mailings zu verhindern. Die
            Daten aus der Blacklist werden nur für diesen Zweck verwendet und
            nicht mit anderen Daten zusammengeführt. Dies dient sowohl Ihrem
            Interesse als auch unserem Interesse an der Einhaltung der
            gesetzlichen Vorgaben beim Versand von Newslettern (berechtigtes
            Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO). Die Speicherung
            in der Blacklist ist zeitlich nicht befristet. Sie können der
            Speicherung widersprechen, sofern Ihre Interessen unser berechtigtes
            Interesse überwiegen.
          </p>
          <br />
          <p class="fs-tertiary-heading fw-bold">5. Plugins und Tools</p>
          <br />
          <p class="fw-bold">YouTube mit erweitertem Datenschutz</p>
          <p>
            Diese Website bindet Videos der YouTube ein. Betreiber der Seiten
            ist die Google Ireland Limited („Google“), Gordon House, Barrow
            Street, Dublin 4, Irland.
          </p>
          <br />
          <p>
            Wir nutzen YouTube im erweiterten Datenschutzmodus. Dieser Modus
            bewirkt laut YouTube, dass YouTube keine Informationen über die
            Besucher auf dieser Website speichert, bevor diese sich das Video
            ansehen. Die Weitergabe von Daten an YouTube-Partner wird durch den
            erweiterten Datenschutzmodus hingegen nicht zwingend ausgeschlossen.
            So stellt YouTube – unabhängig davon, ob Sie sich ein Video ansehen
            – eine Verbindung zum Google DoubleClick-Netzwerk her.
          </p>
          <br />
          <p>
            Sobald Sie ein YouTube-Video auf dieser Website starten, wird eine
            Verbindung zu den Servern von YouTube hergestellt. Dabei wird dem
            YouTube-Server mitgeteilt, welche unserer Seiten Sie besucht haben.
            Wenn Sie in Ihrem YouTube-Account eingeloggt sind, ermöglichen Sie
            YouTube, Ihr Surfverhalten direkt Ihrem persönlichen Profil
            zuzuordnen. Dies können Sie verhindern, indem Sie sich aus Ihrem
            YouTube-Account ausloggen.
          </p>
          <br />
          <p>
            Des Weiteren kann YouTube nach Starten eines Videos verschiedene
            Cookies auf Ihrem Endgerät speichern oder vergleichbare
            Wiedererkennungstechnologien (z.B. Device-Fingerprinting) einsetzen.
            Auf diese Weise kann YouTube Informationen über Besucher dieser
            Website erhalten. Diese Informationen werden u. a. verwendet, um
            Videostatistiken zu erfassen, die Anwenderfreundlichkeit zu
            verbessern und Betrugsversuchen vorzubeugen.
          </p>
          <br />
          <p>
            Gegebenenfalls können nach dem Start eines YouTube-Videos weitere
            Datenverarbeitungsvorgänge ausgelöst werden, auf die wir keinen
            Einfluss haben.
          </p>
          <br />
          <p>
            Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden
            Darstellung unserer Online- Angebote. Dies stellt ein berechtigtes
            Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine
            entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung
            ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. A DSGVO; die
            Einwilligung ist jederzeit widerrufbar.
          </p>
          <br />
          <p>
            Weitere Informationen über Datenschutz bei YouTube finden Sie in
            deren Datenschutzerklärung unter:
            https://policies.google.com/privacy?hl=de.
          </p>
          <br />
          <p class="fw-bold">Google Maps</p>
          <p>
            Diese Seite nutzt den Kartendienst Google Maps. Anbieter ist die
            Google Ireland Limited („Google“), Gordon House, Barrow Street,
            Dublin 4, Irland.
          </p>
          <br />
          <p>
            Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre
            IP-Adresse zu speichern. Diese Informationen werden in der Regel an
            einen Server von Google in den USA übertragen und dort gespeichert.
            Der Anbieter dieser Seite hat keinen Einfluss auf diese
            Datenübertragung. Wenn Google Maps aktiviert ist, kann Google zum
            Zwecke der einheitlichen Darstellung der Schriftarten Google Web
            Fonts verwenden. Beim Aufruf von Google Maps lädt Ihr Browser die
            benötigten Web Fonts in ihren Browsercache, um Texte und
            Schriftarten korrekt anzuzeigen.
          </p>
          <br />
          <p>
            Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden
            Darstellung unserer Online- Angebote und an einer leichten
            Auffindbarkeit der von uns auf der Website angegebenen Orte. Dies
            stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f
            DSGVO dar. Sofern eine entsprechende Einwilligung abgefragt wurde,
            erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6
            Abs. 1 lit. A DSGVO; die Einwilligung ist jederzeit widerrufbar.
          </p>
          <br />
          <p>
            Die Datenübertragung in die USA wird auf die
            Standardvertragsklauseln der EU-Kommission gestützt. Details finden
            Sie hier:
            <a href="https://privacy.google.com/businesses/gdprcontrollerterms/">
              https://privacy.google.com/businesses/gdprcontrollerterms/
            </a>
            und
            <a href="https://privacy.google.com/businesses/gdprcontrollerterms/sccs/">
              https://privacy.google.com/businesses/gdprcontrollerterms/sccs/
            </a>
            .
          </p>
          <br />
          <p>
            Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der
            Datenschutzerklärung von Google:
            <a href="https://policies.google.com/privacy?hl=de">
              https://policies.google.com/privacy?hl=de
            </a>
            .
          </p>
          <br />
          <p>
            Quelle:
            <a href="https://www.datenschutzerklaerung.de">
              https://www.datenschutzerklaerung.de
            </a>
          </p>
        </div>
      </div>
    );
  }
}
