import React from "react";
import Home from "./pages/Home";
import About from "./pages/About";
import Blog from "./pages/Blog";
import Events from "./pages/Events";

function MainPage() {
  return (
    <>
      <Home />
      <About />
      <Blog />
      <Events />
    </>
  );
}

export default MainPage;
