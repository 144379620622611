import React from "react";
import { useState } from "react";
import Modal from "../components/Modal";
import EventModal from "../components/Event/EventModal";
import SuggesstionModal from "../components/Event/SuggesstionModal";
import EventCard from "../components/Event/EventCard";

function Events() {
  const [showEventModal, setShowEventModal] = useState(false);
  const [showSuggModal, setShowSuggModal] = useState(false);
  const eventList = [
    {
      date: "19.03.2025",
      time: "-",
      title: "4. Dialogreihe MiWi Lausitz",
      location: "Herzberg (Elster) ",
      signUpActiv: false,
      readyForSignUp: "(wird noch verkündet)",
    },
  ];

  const closeEventModal = () => {
    setShowEventModal(false);
  };

  const closeSuggModal = () => {
    setShowSuggModal(false);
  };

  const onCardClick = () => {
    setShowEventModal(!showEventModal);
  };

  const onSuggCardClick = () => {
    setShowSuggModal(!showSuggModal);
  };

  const RenderEventModal = () => {
    if (showEventModal) {
      return (
        <Modal
          ContentObject={EventModal}
          ContentProps={{}}
          ContentActions={{ closeModal: closeEventModal }}
          closeModalFunction={closeEventModal}
        />
      );
    }
    return <></>;
  };

  const RenderSuggModal = () => {
    if (showSuggModal) {
      return (
        <Modal
          ContentObject={SuggesstionModal}
          ContentProps={{}}
          ContentActions={{ closeModal: closeSuggModal }}
          closeModalFunction={closeSuggModal}
        />
      );
    }
    return <></>;
  };

  return (
    <section id="events">
      <RenderEventModal />
      <RenderSuggModal />
      <div className="padding-block-700 padding-inline">
        <h1 className="fs-primary-heading fw-bold text-center">
          Veranstaltungen
        </h1>
        <div className="event-list-header">
          <h2 className="ap-year fw-bold"></h2>
        </div>
        <div className="margin-block-500 horizontal-line"></div>
        <div className="ap-card-list">
          {eventList.map((event) => {
            if (event.signUpActiv)
              return <EventCard item={event} ClickAction={onCardClick} />;
            else {
              return <EventCard item={event} />;
            }
          })}
        </div>
        <div className="margin-block-500 horizontal-line"></div>
        <div
          className="btn"
          onClick={onSuggCardClick}
          data-type="inverted"
          data-section="ap">
          Themenvorschläge
        </div>
      </div>
    </section>
  );
}

export default Events;
